<template>
  <area-container>
    <area-nav :links="links"></area-nav>
    <area-content>
      <router-view></router-view>
    </area-content>
  </area-container>
</template>

<script>
export default {
  name: "ReceptionLayout",
  data() {
    return {
      links: [
        { 
          route: "reception.Reception", 
          name: this.$tk("Common.General.Reception") 
        }
      ]
    }
  }
}
</script>
